.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bolder;
    font-size: 20px;
    padding-bottom: 15px;
}

.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e8e8e8;    
}

.separator::before {
    margin-right: .25em;
}

.separator::after {
    margin-left: .60em;    
}