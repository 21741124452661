.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}


/* iframe {
  display: none;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img.JSSE-Logo-Revisualized---BLUE {
  width: 225px;
  height: 131px;
  margin: 5px 109px 6px 21px;
  object-fit: contain;
}

.topHeader .header {
  width: 1920px;
  height: 60px;
  background-color: #1f1f1f;
}

img.header {
  width: 1920px;
  height: 160px;
  margin: 0 0 11px;
  object-fit: contain;
}

button.make-a-pledge {
  width: 148px;
  height: 32px;
  background-color: #269b48;
}

button.read-more {
  width: 124px;
  height: 32px;
  background-color: #00aed8;
}

button.donate {
  width: 102px;
  height: 32px;
  background-color: #e36e19;
}